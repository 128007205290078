.bugreporting{
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

.bugreporting-form {
    max-width: 350px;
    padding: 40px;
    background-color: #F5F5F7;
    border-radius: 10px;
  }

  .bugreporting-form label {
    display: block;
    margin: 30px auto;
    font-weight: bold;
  }

  .bugreporting-form .title{
    color: #718347;
    font-size: 30px;
  }

  .bugreporting-form span {
    display: block;
    margin-bottom: 6px;
    color: black;
  }
  .bugreporting-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }

  .bugreporting-form textarea{
    width: 100%;
    height: 100px;
  }

  .bugreporting-form .bottone{
    color: black;
    padding: 12px 16px;
    background-color: #c2e273;
    border-radius: 50px;
    font-weight: bold;
  }

  @media only screen and (max-width: 800px){
    .bugreporting-form{
      padding-left: 40px;
      padding-right: 40px;
    }
    .bugreporting-form .title{
    }

    .bugreporting-form span{
      font-size: 14px;
    }

    .bugreporting-form label {
      margin: 20px auto;
    }

    .bugreporting-form .bottone{
      margin-top: 5px;
    }
  }