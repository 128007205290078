.changepass{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.changepass-form {
    max-width: 400px;
    padding: 20px;
    min-width: 350px;
    background-color: #F5F5F7;
    border-radius: 10px;
  }
  .changepass-form label {
    display: block;
    margin: 30px auto;
  }
  .changepass-form .title{
    color: #718347;
  }
  .changepass-form span {
    display: block;
    margin-bottom: 6px;
    color: black;
    font-weight: bold;
  }
  .changepass-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }

  .changepass-form .bottone{
    width: 100%;
    margin-top: 20px;
    color: black;
    padding: 12px 16px;
    background-color: #c2e273;
    border-radius: 50px;
    font-weight: bold;
  }