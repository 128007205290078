.launch-screen{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* .launch-screen img:hover{
  transform: scale(1.2);
  z-index: 1001;
  transition-duration: 0.5s;
} */

.launch-screen .title-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
}

.launch-screen .logo{
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 10px;
}

.launch-screen .title{
  font-size: 50px;
  color: black;
  font-weight: bold;

  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em;
  animation: 
    typing 2.5s steps(30, end),
    blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black; }
}

.launch-screen .description{
  color: black;
  font-size: 20px;
  margin: 20px;
  text-align: justify;
  margin-top: 20px;
  margin-left: 200px;
  margin-right: 200px;
}

.launch-screen button{
  margin-bottom: 30px;
}

@media only screen and (max-width: 800px){

  .launch-screen .title{
    font-size: 20px;
  }

  .launch-screen .logo{
    width: 40px;
    height: 40px;
  }
  
  .launch-screen .description{
    font-size: 16px;
    margin-left: 30px;
    margin-right: 30px;
  }
}