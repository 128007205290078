.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  margin-bottom: 20px;
}


.carousel img{
  object-fit: contain;
}

.slide {
  border-radius: 0.5rem;
  width: 90vw;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50px;
  background-color: white;
}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: 1rem;
}

.arrow-right {
  right: 1rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}

@media only screen and (max-width: 800px){

  .carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }

  .slide {
    border-radius: 0.5rem;
    width: 100vw;
    height: 30vh;
    animation: op 1s;
  }
}