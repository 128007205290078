.popup-message .message{
  display: flex;
  justify-content: center;
  color: black;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
}

.popup-message .refresh{
  display: flex;
  justify-content: center;
}