.signup{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
}

.signup-form .avatars{
  display: flex;
  justify-content: space-between;
  overflow: scroll;
}

.signup-form .avatar{
  padding: 5px;
  width: 70px;
  height: 70px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: contain;
  cursor: pointer;
  background-color: white;
}

.signup-form .avatar.active{
  border: 3px solid green;
}

.signup-form .backhome{
  color: black;
  text-decoration: underline;
  position: absolute;
  transform: translate(-40%,-130%);
}

.signup-form {
    max-width: 350px;
    min-width: 350px;
    padding: 40px;
    background-color: #F5F5F7;
    border-radius: 10px;
  }
  .signup-form .title{
    color: #718347;
  }
  .signup-form label {
    display: block;
    margin: 30px auto;
  }
  .signup-form span {
    display: block;
    margin-bottom: 6px;
    color: black;
    font-weight: bold;
  }
  .signup-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }

  .signup-form .bottone{
    width: 100%;
    margin-top: 20px;
    color: black;
    padding: 12px 16px;
    background-color: #c2e273;
    border-radius: 50px;
    font-weight: bold;
  }

  .conditions{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  #terms{
    margin: 0;
    width: 22px;
    height: 22px;
  }

  #label-terms{
    margin: 0;
  }


  @media only screen and (max-width: 800px){
    .signup-form .title{
      font-size: 30px;
    }

    .signup-form span{
      font-size: 14px;
    }

    .signup-form label {
      margin: 20px auto;
    }

    .signup-form .bottone{
      margin-top: 10px;
    }
  }