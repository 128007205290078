.page404{
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page404 span{
  padding: 10px;
  background-color: red;
  color: white;
  border-radius: 10px;
}