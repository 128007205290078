.trip-card-front{
  background-color: white;
/*   Dark mode
  background-color: #1B1B1E; */
  padding: 13px;
  border-radius: 20px;
  width: 350px;
  height: 200px;
  display: grid;
  grid-template-rows: 15% 25% 5% 10% 5% 25% 15%;
  grid-template-columns: 35% 30% 35%;
  box-shadow: 2px 4px 12px #00000014;
  box-shadow: 2px 4px 16px #00000029;
}

.trip-card-front span{
  color: black;
/*   Dark mode
  color: white; */
  font-weight: bold;
}

/*header*/
.trip-card-front .user{
  display: flex;
  align-items: center;
  justify-self: start;
  align-self: flex-start;
  height: 100%;
}

.trip-card-front .user img{
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.trip-card-front .user span{
  margin-left: 5px;
  font-size: 14px;
  color: #708145;
}

.trip-card-front .date{
  display: flex;
  align-items: center;
  justify-items: flex-end;
  grid-column-start: 3;
  justify-self: flex-end;
  align-self: flex-start;
  height: 100%;
}

.trip-card-front .date-icon{
  width: 20px;
  height: 20px;
}

.trip-card-front .date span{
  margin-left: 6px;
  font-size: 12px;
  color: #708145;
}

/*body*/
.trip-card-front .i_place{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-self: center;
  align-self: center;
  font-size: 16px;
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.trip-card-front .i_time{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  justify-self: center;
  align-self: center;
  font-size: 14px;
}

.trip-card-front .distance-icon{
  width: 40px;
  height: 40px;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 4;
  justify-self: flex-start;
  align-self: center;
}

.trip-card-front .f_time{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 5;
  justify-self: center;
  align-self: center;
  font-size: 14px;
}

.trip-card-front .f_place{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 6;
  grid-row-end: 6;
  justify-self: center;
  align-self: center;
  font-size: 16px;
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

/*footer*/

.trip-card-front .people{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 7;
  justify-self: start;
  align-self: flex-end;
}

.trip-card-front .people .people-icon{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.trip-card-front .veichle{
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 7;
  display: flex;
  align-items: center;
  justify-self: end;
  align-self: flex-end;
}

.trip-card-front .veichle .veichle-icon{
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.trip-card-front .veichle span{
  margin-right: 5px;
}

@media only screen and (max-width: 800px){

  .trip-card-front{
    width: 320px;
  }

  .trip-card-front span{
    color: black;
  /*   Dark mode
    color: white; */
    font-weight: bold;
    font-size: 14px;
  }
}