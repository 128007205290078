@import "https://cdn.jsdelivr.net/npm/bulma@1.0.1/css/bulma.min.css";

html, body, #root, .App {
  height: 100%;
  width: 100%;
  background-color: white;
}

html{
  background-attachment: fixed;
  background-size: cover;
  background-color: white;
/*   Dark mode
  background-color: #1B1B1E; */
}

*{
  font-family: "Plus_Jakarta_Sans","Roboto",-apple-system,"Segoe UI",sans-serif;
}

:root{
  --primary-font-web: 16px;
}

/*General*/

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.error2 {
  color: black;
  background: gray;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}