.trip-card-back{
  padding: 20px;
  border-radius: 20px;
  width: 350px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: gray;
  box-shadow: 2px 4px 16px #00000029;
}

.trip-card-back .description{
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #f7f7f7;
}

.trip-card-back .description .description-title{
  font-weight: bold;
}

.trip-card-back .description .description-content{
  max-height: 50%;
  word-wrap: break-word;
}

/*Buttons*/

.trip-card-back .buttons{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trip-card-back .buttons .chat{
  display: flex;
  align-items: center;
  color: black;
  border: 1px solid gray;
  background-color: #c2e273;
  font-size: 14px;
  font-weight: bold;
  margin: 4px;
  padding: 12px 16px;
  border-radius: 50px;
}

.trip-card-back .buttons .chat-icon{
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.trip-card-back .button-book{
  background-color: #c2e273;
  color: black;
  align-self: flex-end;
  font-weight: bold;
  margin: 4px;
  padding: 12px 16px;
  border-radius: 50px;
  font-size: 14px;
}

.trip-card-back .button-booked{
  background-color: #E76B6B;
  color: white;
  align-self: flex-end;
  font-weight: bold;
  margin: 4px;
  padding: 12px 16px;
  border-radius: 50px;
  font-size: 14px;
}

.trip-card-back .button-soldout{
  color: black;
  align-self: flex-end;
  font-weight: bold;
  margin: 4px;
  padding: 12px 16px;
  border-radius: 50px;
  font-size: 16px;
}

.trip-card-back .edit{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trip-card-back .button-edit{
  background-color: #c2e273;
  color: black;
  align-self: flex-end;
  font-weight: bold;
  margin: 4px;
  padding: 10px 12px;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 5px;
}

.trip-card-back .button-delete{
  width: 16px;
  height: 16px;
}

.trip-card-back .confirm{
  animation: scaling 0.3s;
}

@keyframes scaling{
  0%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
}

.trip-card-back .button-confirm-yes{
  background-color: red;
  color: white;
  font-weight: bold;
  margin: 4px;
  padding: 12px 16px;
  border-radius: 50px;
  font-size: 14px;
}

.trip-card-back .button-confirm-no{
  background-color: gray;
  padding: 8px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 20px;
  color: white;
  margin-right: 5px;
}

@media only screen and (max-width: 800px){

  .trip-card-back{
    width: 320px;
  }

  .trip-card-back span{
    font-size: 14px;
  }
}
