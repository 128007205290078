/*Loading*/
.loading{
  text-align: center;
}

.loading img{
  width: 250px;
  height: 250px;
  object-fit: contain;
  animation: move 0.3s infinite;
}