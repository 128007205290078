.hero{
  margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.hero .description{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  color: black;
  font-weight: bold;
  text-align: center;
  max-width: 100%;
  display: inline-block;
}

.hero .description .colored-text{
  color: #c2e273;
  padding: 0px;
  margin: 0px;
  white-space: nowrap
}

.nowrap{
  white-space: wrap;
}

@media only screen and (max-width: 800px){

  .hero{
    margin-left: 40px;
    margin-right: 40px;
    text-wrap: wrap;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .hero .description{
    font-size: 20px;
  }
}