.filterbar{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 70px;
  padding-left: 70px;
  width: 100vw;
  transform: translateY(50%)
}

.filterbar button {
  font-family: inherit;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
  padding: 10px 12px;
  border-radius: 50px;
  background-color: #c6c6c6;
  font-size: 16px;
}

.filterbar button:hover{
  background-color: #526329;
  color: #C4E677;
}

.filterbar button.active {
  background-color: #c2e273;
}

.filterbar button.active:hover{
  color: black;
}

@media only screen and (max-width: 800px){
  .filterbar{
    padding-left: 20px;
    padding-right: 20px;
  }

  .filterbar button {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
  }
}