.home{
    padding-top: 65px;
    padding-bottom: 45px;
}

.dashboard{
    background-color: #c6c6c6;
    background-color: #F5F5F7;
/*     Dark mode
    background-color: #1B1B1E; */
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 25px;
    margin-left: 30px;
    margin-right: 30px;
}

@keyframes move {
    0%{
        transform: rotate(-5deg);
    }
    50%{
        transform: rotate(5deg);
    }
    100%{
        transform: rotate(-5deg);
    }
}

.empty-trips{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 800px){
    .dashboard{
        margin-left: 10px;
        margin-right: 10px;
    }
}