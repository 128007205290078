.trips{
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 40px;
    justify-content: space-around;
}

.flip-card {
    width: 350px;
    height: 200px;
    perspective: 1000px;
  }

  .flip-icon{
    position: absolute;
    right: -5px;
    top: -5px;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner{
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
  }
  
  /* Style the back side */
  .flip-card-back {
    transform: rotateY(180deg);
  }


@media only screen and (max-width: 800px){

  .trips{
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 320px);
    gap: 40px;
    justify-content: space-around;
  }

  .flip-card {
    width: 320px;
  }

  .flip-card:hover .flip-card-inner{
    transform: none;
  }
}
  

