.navbar{
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    background-color: transparent;
/*     Dark mode
    background-color: #1B1B1E; */
    position: fixed;
    width: 100%;
    height: 60px;
    align-items: center;
    overflow: hidden;
    padding: 40px;
    /* backdrop-filter: blur(10px); */
    overflow: visible;
    backdrop-filter: blur(3px);
}


/*Leading*/

.navbar .leading a{
    display: flex;
    align-items: center;
}

.navbar .leading .logo-image{
    display: inline-block;
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.navbar .logo-title{
    color: #718347;
    font-size: 25px;
    font-weight: 700;
}

/*Actions*/

.navbar .actions{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar .actions .login-button{
    color: black;
    padding: 12px 16px;
    border-radius: 50px;
    background-color: #c2e273;
    font-weight: bold;
}

.navbar .actions .plus-image{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.navbar .actions .new-trip{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-right: 25px;
}

.navbar .actions a{
    color: black;
}

.navbar .actions .new-trip{
    color: black;
    padding: 12px 16px;
    border-radius: 50px;
    background-color: #c2e273;
}

/*Profile Logo*/
.profile-logo{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    width: 50px;
    padding: 1px;
    
}

.profile-floor{
    display: flex;
    width: 100px;
    transform: translateX(50%);
}

.profile-floor .floor{
    width: 100%;
    height: 3px;
    margin-right: 5px;
    background-color: black;
    animation: move_floor 3s infinite linear;
}


@keyframes move_floor {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-110%);
    }
}

/*Profile Icon*/
.profile-icon{
    width: 55px;
    height: 55px;
    cursor: pointer; 
    margin-left: 30px;
    object-fit: contain;
    animation: move 0.3s infinite;
}

@keyframes move {
    0%{
        transform: translateY(-1px);
    }
    50%{
        transform: translateY(1px);
    }
    100%{
        transform: translateY(-1px);
    }
}

/* @keyframes move {
    0%{
        transform: rotate(-5deg);
    }
    50%{
        transform: rotate(5deg);
    }
    100%{
        transform: rotate(-5deg);
    }
} */


/*Profile box*/

.profile-box-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
}

.profile-box{
    display: flex;
    position: fixed;
    right: 45px;
    top: 70px;  
    width: 300px;
    height: auto;
    border: 1px solid white;
    border-radius: 20px;
    padding: 10px;
    background-color: #c6c6c6;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    animation: scaling2 0.3s;
    transform-origin: top right;
}

.profile-box img{
    width: 55px;
    height: 55px;
    cursor: pointer; 
    object-fit: contain;
}

.profile-box .user{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-box .edit-icon{
    width: 25px;
    height: 25px;
}

.profile-box .user span{
    margin-left: 15px;
    color: #718347;
}

.profile-box{
    color: black;
    font-weight: 600;
}

.profile-box a{
    margin-top: 7px;
    color: black;
    text-decoration: underline;
}

.profile-box button{
    margin-top: 7px;
    text-decoration: underline;
}

.profile-box .logout{
    color: #E76B6B;
    font-weight: bold;
}

@keyframes scaling2{
    0%{
      transform: scale(0);
    }
    100%{
      transform: scale(1);
    }
  }

/*Avatars*/

.navbar .popup-avatars span{
    display: block;
    margin-bottom: 20px;
    color: black;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}


.navbar .avatars{
    display: grid;
    grid-template-columns: repeat(4, 70px);
    gap: 20px;
}

.navbar .avatar{
    padding: 5px;
    width: 70px;
    height: 70px;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: contain;
    cursor: pointer;
    background-color: white;
}

.navbar .avatar.active{
    border: 3px solid green;
}

.navbar .popup-avatars .buttons{
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
}

.navbar .popup-avatars button{
    color: black;
    font-weight: bold;
    padding: 12px 16px;
    border-radius: 50px;
    background-color: #c2e273;
    font-size: 16px;
}

.navbar .popup-avatars .cancel-icon{
    color: white;
    background-color: red;
}

@media only screen and (max-width: 800px){

    .navbar{
        padding: 20px;
        padding-top: 40px;
    }

    .navbar .logo-title{
        display: none;
    }



    /* .navbar .actions{
        background-color: red;
        width: 100%;
        justify-content: space-between;
    }*/

    .navbar .actions .new-trip{
        font-size: 14px;
        
    } 

    /*Avatars*/
    .navbar .avatars{
        grid-template-columns: repeat(3, 70px);
    }
}
