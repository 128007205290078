.passreset{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passreset-form {
    max-width: 400px;
    padding: 40px;
    min-width: 350px;
    background-color: #F5F5F7;
    border-radius: 10px;
  }

  .passreset .backhome{
    color: black;
    text-decoration: underline;
    position: absolute;
    transform: translate(-40%,-130%);
  }

  .passreset-form label {
    display: block;
    margin: 30px auto;
  }
  .passreset-form .title{
    color: #718347;
  }
  .passreset-form span {
    display: block;
    margin-bottom: 6px;
    color: black;
    font-weight: bold;
  }
  .passreset-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }

  .passreset-form .bottone{
    width: 100%;
    margin-top: 20px;
    color: black;
    padding: 12px 16px;
    background-color: #c2e273;
    border-radius: 50px;
    font-weight: bold;
  }


  @media only screen and (max-width: 800px){
    .passreset-form .title{
      font-size: 30px;
    }

    .passreset-form span{
      font-size: 14px;
    }

    .passreset-form label {
      margin: 20px auto;
    }

    .passreset-form .bottone{
      margin-top: 10px;
    }
  }