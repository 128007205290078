.newtrip{
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

.newtrip-form {
    max-width: 350px;
    padding: 40px;
    background-color: #F5F5F7;
    border-radius: 10px;
  }

  .newtrip .backhome{
    color: black;
    text-decoration: underline;
    position: absolute;
    transform: translate(-40%,-130%);
  }

  .newtrip-form label {
    display: block;
    margin: 30px auto;
    font-weight: bold;
  }

  .newtrip-form .title{
    color: #718347;
  }

  .newtrip-form span {
    display: block;
    margin-bottom: 6px;
    color: black;
  }
  .newtrip-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }

  .newtrip-form textarea{
    width: 100%;
    height: 100px;
  }

  .newtrip-form .bottone{
    color: black;
    padding: 12px 16px;
    background-color: #c2e273;
    border-radius: 50px;
    font-weight: bold;
  }

  @media only screen and (max-width: 800px){
    .newtrip-form{
      padding-left: 40px;
      padding-right: 40px;
    }
    .newtrip-form .title{
      font-size: 30px;
    }

    .newtrip-form span{
      font-size: 14px;
    }

    .newtrip-form label {
      margin: 20px auto;
    }

    .newtrip-form .bottone{
      margin-top: 5px;
    }
  }