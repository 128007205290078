.sponsor{
  position: fixed;
  bottom: 0px;
  left: 50px;
  padding: 10px;
}

.sponsor img{
  width: 170px;
  border-radius: 10px;
  border: 1px solid black;
}

@media only screen and (max-width: 800px){
  .sponsor{
    left: 20px;
  }
  .sponsor img{
    width: 120px;
  }
}