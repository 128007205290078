.popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
}

.popup-content{
  padding: 30px;
  max-width: 480px;
  margin: 200px auto;
  background: white;
  border-radius: 10px;
}