/*Chat Logo*/

.chat .chat-logo {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #c2e273;
  border-radius: 50%;
  color: white;
  padding: 28px;
  cursor: pointer;
  box-shadow: 2px 4px 16px #00000029;
}

.chat .chat-logo img{
  width: 30px;
  height: 30px;
}

/*ChatBox*/

.chat .chat-box {
  display: none;
  background: #efefef;
  position: fixed;
  right: 30px;
  bottom: 50px;  
  width: 350px;
  max-width: 85vw;
  max-height: 100vh;
  border-radius: 5px;
  animation: scaling 0.3s;
  transform-origin: bottom right;
}

@keyframes scaling{
  0%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
}

  /*Users*/
  .chat-box .users{
    padding: 5px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .chat-box .admin{
    font-weight: bold;
    color: #708145;
  }

  .chat-box .admin::after{
    content: "-";
  }

  .chat-box .users .user{
    color: black;
  }

  /*Header*/
  .chat .chat-box .header {
    background-color: #718347;
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
  }

  .chat .chat-box .close {
    cursor: pointer;
    width: 20px;
    color: gray;
  }

/*Body*/
.chat .chat-box .body {
  position: relative;  
  height: 100%;  
  height: auto;
  border: 1px solid #ccc;  
  overflow: hidden;
  padding-bottom: 5px;
}

.chat .chat-box .chat-logs {
  padding: 15px; 
  height: 370px;
  overflow-y: scroll;
}

.chat-logs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #718347;
}

.chat-logs::-webkit-scrollbar {
  width: 5px;  
  background-color: #718347;
}

.chat-logs::-webkit-scrollbar-thumb {
  background-color: #718347;
}

.chat-msg {
  clear: both;    
}

/*user*/
.chat-msg.user .msg-avatar img {
  width: 45px;
  height: 45px;
  float: left;
  cursor: pointer;
  object-fit: contain;
}

/*self*/
.chat-msg.self .msg-avatar img {
  width: 45px;
  height: 45px;
  float: right;
  cursor: pointer; 
  object-fit: contain;
}

.chat-msg.self > .msg-text {  
  float: right;
  margin-right: 10px;
  background: #718347;
  color: white;
}

.chat-msg.user > .msg-text .user-name{
  color: black;
  font-weight: bold;
  text-align: start;
  font-size: 14px;
}

.msg-text {
  background: white;
  padding: 10px 15px;  
  color: #666;
  max-width: 75%;
  word-wrap: break-word;
  float: left;
  margin-left: 10px; 
  position: relative;
  margin-bottom: 20px;
  border-radius: 30px;
}

.chat-input form{
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.chat-input input{
  background: #f4f7f9;
  width: 100%; 
  height: 47px;  
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  border: none;
  resize: none;
  outline: none;
  border: 1px solid #ccc;
  color: #888;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  font-size: 16px;
}
#chat-input > form {
  margin-bottom: 0;
}
#chat-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ccc;
}
#chat-input::-moz-placeholder { /* Firefox 19+ */
  color: #ccc;
}
#chat-input:-ms-input-placeholder { /* IE 10+ */
  color: #ccc;
}
#chat-input:-moz-placeholder { /* Firefox 18- */
  color: #ccc;
}

.chat-input button{
}

.chat-input .send-icon{
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;

}

.cm-msg-button > ul > li {
  list-style: none;
  float: left;
  width: 50%;
}
.cm-msg-button {
  clear: both;
  margin-bottom: 70px;
}

@media only screen and (max-width: 800px){

  .chat .chat-logo{
    padding: 20px;
    right: 30px;
    bottom: 30px;
  }

  .chat .chat-logo img{
    width: 30px;
    height: 30px;
  }

  .chat .chat-box{
    z-index: 1001;
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    max-height: 100dvh;
    max-width: 100dvw;
  }

  .chat-box .users{
    height: 30px;
  }

  .chat .chat-box .header {
    height: 70px;
  }

  .chat .chat-box .chat-logs {
    width: 100dvw;
    height: calc(100dvh - 70px - 60px - 30px);
  }

  .chat-input input{
    height: 60px;
  }
}
